
  import { Component, Emit, Prop, Vue } from "vue-property-decorator";

  @Component({})
  export default class Stepper extends Vue {
    @Prop({ required: true })
    protected readonly steps!: Array<string>;

    @Prop({ required: true })
    protected readonly active!: string;

    @Prop({ default: true })
    protected readonly clickable!: boolean;

    @Prop({ default: false })
    protected readonly pass!: boolean;

    @Emit("click")
    onStepClick(): void {
      return;
    }

    get activeStepIdx(): number {
      return this.steps.findIndex(step => step === this.active);
    }

    stepClasses(step: string, stepIdx: number): string {
      const pre = "lz-stepper__step--";
      const classes: Array<string> = [];

      if (this.active === step) {
        classes.push("active");
      }
      if (this.clickable) {
        classes.push("clickable");
      }
      if (this.pass && this.activeStepIdx > stepIdx) {
        classes.push("pass");
      }

      return classes.map((cl: string) => pre + cl).join(" ");
    }

    stepStyle(): object {
      return {
        width: `${100 / this.steps.length}%`
      };
    }
  }
