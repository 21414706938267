
  import { Component, Vue } from "vue-property-decorator";
  import LzButton from "@/components/Button.vue";
  import LzTable from "@/components/Table.vue";
  import LzStepper from "@/components/Stepper.vue";
  import LzModal from "@/components/Modal.vue";
  import { apiShop } from "../api";
  import { namespace } from "vuex-class";

  const auth = namespace("auth");

  @Component({ components: { LzButton, LzTable, LzStepper, LzModal } })
  export default class Read extends Vue {
    @auth.State("id")
    public ongId!: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    active = "products";
    productsQuery = "";
    products: any[] = [];

    showOrderModal = false;
    modalOrder = null;

    listFields = [
      { id: "title", label: this.$t("shop.read.productsTable.name") },
      {
        id: "discount",
        label: this.$t("shop.read.productsTable.discount")
      },
      { id: "price", label: this.$t("shop.read.productsTable.price") },
      {
        id: "status",
        label: this.$t("shop.read.productsTable.status.label")
      },
      { id: "actions", label: this.$t("shop.read.productsTable.actions") }
    ];

    orders: any[] = [];

    ordersFields = [
      { id: "name", label: this.$t("shop.read.ordersTable.name") },
      { id: "code", label: this.$t("shop.read.ordersTable.trackingCode") },
      { id: "products", label: this.$t("shop.read.ordersTable.products") },
      { id: "total", label: this.$t("shop.read.ordersTable.total") },
      { id: "status", label: this.$t("shop.read.ordersTable.status") },
      { id: "actions", label: this.$t("shop.read.ordersTable.actions") }
    ];

    orderFields = [
      { id: "name", label: this.$t("shop.read.modalOrder.table.name") },
      {
        id: "products",
        label: this.$t("shop.read.modalOrder.table.products")
      },
      { id: "price", label: this.$t("shop.read.modalOrder.table.price") },
      { id: "total", label: this.$t("shop.read.modalOrder.table.total") }
    ];

    modalOrderOptions = {
      pending: this.$t("shop.read.modalOrder.status.options.pending"),
      accepted: this.$t("shop.read.modalOrder.status.options.accepted"),
      sent: this.$t("shop.read.modalOrder.status.options.sent"),
      delivered: this.$t("shop.read.modalOrder.status.options.delivered")
    };

    viewOrder(row: any): void {
      this.modalOrder = row;
      this.showOrderModal = true;
    }

    closeOrderModal(): void {
      this.modalOrder = null;
      this.showOrderModal = false;
    }

    saveOrder(row: any): void {
      const body = {
        status: row.status,
        shipment_method_url: row.shipment_method_url,
        tracking_code: row.tracking_code,
        user_email: row.User.email
      };
      apiShop.updateOrder(row.id, body).then(() => {
        this.closeOrderModal();
      });
    }

    onModalPull(): void {
      console.log("ww");
    }

    mounted() {
      apiShop.getProducts(this.ongId).then(({ data }) => {
        data.forEach((product: any) => {
          product.status = product.active
            ? this.$t("shop.read.productsTable.status.options.enabled")
            : this.$t("shop.read.productsTable.status.options.disabled");
          product.discount =
            product.discount + `${this.$t("shop.read.percent")}`;
          product.price =
            product.price + `${this.$t("shop.read.currency.euro")}`;
          this.products.push(product);
        });
      });
      apiShop.getOrders(this.ongId).then(({ data }) => {
        data.forEach((order: any) => {
          const orderItem = { ...order } as any;
          orderItem.name = order.User.firstName + order.User.lastName;
          orderItem.code = order.tracking_code;
          orderItem.products = order.OrderDetails[0].Product.title;
          orderItem.total =
            order.amount + `${this.$t("shop.read.currency.euro")}`;
          orderItem.status = order.status;
          this.orders.push(orderItem);
        });
      });
    }
  }
